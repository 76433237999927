import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { useMyContext } from "../store/ContextApi";
import logo from "../logo.png";

const Navbar = () => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const { token, setToken, setCurrentUser, isAdmin, setIsAdmin } =
    useMyContext();

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    setCurrentUser(null);
    setIsAdmin(false);
    navigate("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("JWT_TOKEN") === null) {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-VN18C7ERM9";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    window.gtag("js", new Date());
    window.gtag("config", "G-VN18C7ERM9");
  }, []);

  return (
    <header className="h-16 z-50 text-white bg-gradient-to-r from-blue-500 to-indigo-600 shadow-md flex items-center sticky top-0">
      <nav className="container mx-auto px-6 flex w-full h-full items-center justify-between">
        <Link to="/" className="flex items-center gap-2">
          <img src={logo} alt="Blue Bike Logo" className="h-10" />
          <h3 className="text-3xl font-extrabold font-mono tracking-wide text-yellow-300">
            Blue Bike
          </h3>
        </Link>
        <ul
          className={`lg:flex ${
            headerToggle ? "flex" : "hidden"
          } flex-col lg:flex-row absolute lg:static top-16 left-0 w-full lg:w-auto bg-blue-600 lg:bg-transparent p-4 lg:p-0 space-y-4 lg:space-y-0 lg:space-x-6 text-lg font-medium`}
        >
          <Link
            to="/my-bikes"
            className={`hover:text-yellow-300 ${
              pathName === "/my-bikes" && "font-semibold"
            }`}
          >
            My Bikes
          </Link>
          <Link
            to="/workshops"
            className={`hover:text-yellow-300 ${
              pathName === "/workshops" && "font-semibold"
            }`}
          >
            Workshops
          </Link>
          <Link
            to="/contact"
            className={`hover:text-yellow-300 ${
              pathName === "/contact" && "font-semibold"
            }`}
          >
            Contact
          </Link>
          <Link
            to="/about"
            className={`hover:text-yellow-300 ${
              pathName === "/about" && "font-semibold"
            }`}
          >
            About
          </Link>
          {token ? (
            <>
              <Link
                to="/profile"
                className={`hover:text-yellow-300 ${
                  pathName === "/profile" && "font-semibold"
                }`}
              >
                Profile
              </Link>
              {isAdmin && (
                <Link
                  to="/admin/recent-users"
                  className={`uppercase hover:text-yellow-300 ${
                    pathName.startsWith("/admin") && "font-semibold"
                  }`}
                >
                  Admin
                </Link>
              )}
              <button
                onClick={handleLogout}
                className="bg-red-500 px-4 py-2 rounded-lg hover:bg-red-600"
              >
                LogOut
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className="bg-yellow-400 px-4 py-2 rounded-lg text-black font-semibold hover:bg-yellow-500"
            >
              SignUp
            </Link>
          )}
        </ul>
        <span
          onClick={() => setHeaderToggle(!headerToggle)}
          className="lg:hidden block cursor-pointer text-white text-2xl"
        >
          {headerToggle ? <RxCross2 /> : <IoMenu />}
        </span>
      </nav>
    </header>
  );
};

export default Navbar;
