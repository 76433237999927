import React from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import SellIcon from "@mui/icons-material/Sell";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import HomeIcon from "@mui/icons-material/Home";
import { Home } from "@mui/icons-material";

const Footer = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate(); // Initialize navigation function

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    // Define routes based on index
    const routes = [
      "/home",
      "/used-bike-list-router",
      "/new-bike-list-router",
      "/sell-bike",
      "/contact",
    ];
    navigate(routes[newValue]); // Navigate to the corresponding page
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleNavigation}
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: 3,
        backgroundColor: "#90caf9",
      }}
    >
      <BottomNavigationAction label="Home" icon={<Home />} />
      <BottomNavigationAction label="Used" icon={<DirectionsBikeIcon />} />
      <BottomNavigationAction label="New Bikes" icon={<TwoWheelerIcon />} />
      <BottomNavigationAction label="Sell Bike" icon={<SellIcon />} />
      <BottomNavigationAction label="Support" icon={<SupportAgentIcon />} />
    </BottomNavigation>
  );
};

export default Footer;
