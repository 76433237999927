import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import LandingPage from "./components/LandingPage/LandingPage";
import AccessDenied from "./components/Auth/AccessDenied";
import Admin from "./components/AuditLogs/Admin";
import UserProfile from "./components/Auth/UserProfile";
import ForgotPassword from "./components/Auth/ForgotPassword";
import OAuth2RedirectHandler from "./components/Auth/OAuth2RedirectHandler";
import { Toaster } from "react-hot-toast";
import NotFound from "./components/NotFound";
import ContactPage from "./components/contactPage/ContactPage";
import AboutPage from "./components/aboutPage/AboutPage";
import ResetPassword from "./components/Auth/ResetPassword";
import Footer from "./components/Footer/Footer";
import Booking from "./components/Booking/Booking";
import MyBookings from "./components/Booking/MyBookings";
import NewBikeList from "./components/NewBike/NewBikeList";
import NewBikeDetail from "./components/NewBike/NewBikeDetail";
import UsedBike from "./components/UsedBike/UsedBike";
import MyBikes from "./components/Customer/MyBikes";
import UsedBikeDetail from "./components/UsedBike/UsedBikeDetail";
import UpdateUsedBike from "./components/UsedBike/UpdateUsedBike";
import UsedBikeList from "./components/UsedBike/UsedBikeList";
import WorkshopList from "./components/Workshop/WorkshopList";
import WorkshopDetail from "./components/Workshop/WorkshopDetail";
import DealerHome from "./components/Home/DealerHome";
import Workshop from "./components/Workshop/Workshop";
import DealerWorkshopList from "./components/Workshop/DealerWorkshopList";
import NewBikeDisplayStatusUpdate from "./components/NewBike/NewBikeDisplayStatusUpdate";
import UsedBikeDisplayStatusUpdate from "./components/UsedBike/UsedBikeDisplayStatusUpdate";
import InterestedCustomers from "./components/Dealer/InterestedCustomers";
import CustomerHome from "./components/Home/CustomerHome";
import Home from "./components/Home/Home";
import UsedBikeDetailShort from "./components/UsedBike/UsedBikeDetailShort";
import UpdateNewBike from "./components/NewBike/UpdateNewBike";
import UpdateWorkshop from "./components/Workshop/UpdateWorkshop";
import CustomerUsedBikeInterest from "./components/Interest/CustomerUsedBikeInterest";
import UsedBikeListRouter from "./components/UsedBike/UsedBikeListRouter";
import NewBikeListRouter from "./components/NewBike/NewBikeListRouter";

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="pb-20">
        <Toaster position="bottom-center" reverseOrder={false} />
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/used-bike-detail" element={<UsedBikeDetailShort />} />

          <Route
            path="/create-booking"
            element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            }
          />

          <Route
            path="/my-bookings"
            element={
              <ProtectedRoute>
                <MyBookings />
              </ProtectedRoute>
            }
          />

          <Route path="/access-denied" element={<AccessDenied />} />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute adminPage={true}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/update-new-bike"
            element={
              <ProtectedRoute adminPage={true}>
                <UpdateNewBike />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />

          <Route
            path="/buy-bike"
            element={
              <ProtectedRoute>
                <NewBikeList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/buy-used-bike"
            element={
              <ProtectedRoute>
                <UsedBikeList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/workshops"
            element={
              <ProtectedRoute>
                <WorkshopList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/show-my-workshop"
            element={
              <ProtectedRoute>
                <DealerWorkshopList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-workshop"
            element={
              <ProtectedRoute>
                <Workshop />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dealer-home"
            element={
              <ProtectedRoute>
                <DealerHome />
              </ProtectedRoute>
            }
          />

          <Route
            path="/update-workshop"
            element={
              <ProtectedRoute>
                <UpdateWorkshop />
              </ProtectedRoute>
            }
          />

          <Route
            path="/workshopDetail"
            element={
              <ProtectedRoute>
                <WorkshopDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/newBikeDetail"
            element={
              <ProtectedRoute>
                <NewBikeDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/usedBikeDetail"
            element={
              <ProtectedRoute>
                <UsedBikeDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/updateUsedBike"
            element={
              <ProtectedRoute>
                <UpdateUsedBike />
              </ProtectedRoute>
            }
          />
          <Route
            path="/interest-customers"
            element={
              <ProtectedRoute>
                <InterestedCustomers />
              </ProtectedRoute>
            }
          />

          <Route
            path="/sell-bike"
            element={
              <ProtectedRoute>
                <UsedBike />
              </ProtectedRoute>
            }
          />

          <Route
            path="/my-bikes"
            element={
              <ProtectedRoute>
                <MyBikes />
              </ProtectedRoute>
            }
          />

          <Route
            path="/new-bike-display-status-update"
            element={
              <ProtectedRoute>
                <NewBikeDisplayStatusUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/used-bike-display-status-update"
            element={
              <ProtectedRoute>
                <UsedBikeDisplayStatusUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/customer-home"
            element={
              <ProtectedRoute>
                <CustomerHome />
              </ProtectedRoute>
            }
          />
          <Route path="/home" element={<Home />} />

          <Route path="/access-denied" element={<AccessDenied />} />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute adminPage={true}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wish-list"
            element={
              <ProtectedRoute>
                <CustomerUsedBikeInterest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/used-bike-list-router"
            element={<UsedBikeListRouter />}
          />
          <Route path="/new-bike-list-router" element={<NewBikeListRouter />} />
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
